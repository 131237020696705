module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Proof Blog","short_name":"Proof Blog","start_url":"/","background_color":"#1E43FF","theme_color":"#1E43FF","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8ee9c24ba5ad78b3d8cdd335498f9e35"},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"style":{"overlay":{"position":"","left":"","right":"","bottom":"","top":"","background":""},"content":{"position":"","maxWidth":"","width":"","margin":"","border":"","padding":"","outline":"","borderRadius":"","background":"","left":"","right":"","bottom":"","top":"","overflow":""}},"ariaHideApp":true,"portalClassName":"modal modal--search"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
